import request from '@/utils/request'


// 查询平台消息列表
export function listMessage(query) {
  return request({
    url: '/message/message/list',
    method: 'get',
    params: query
  })
}

// 查询平台消息分页
export function pageMessage(query) {
  return request({
    url: '/message/message/page',
    method: 'get',
    params: query
  })
}

// 查询平台消息详细
export function getMessage(data) {
  return request({
    url: '/message/message/detail',
    method: 'get',
    params: data
  })
}

// 新增平台消息
export function addMessage(data) {
  return request({
    url: '/message/message/add',
    method: 'post',
    data: data
  })
}

// 修改平台消息
export function updateMessage(data) {
  return request({
    url: '/message/message/edit',
    method: 'post',
    data: data
  })
}

// 删除平台消息
export function delMessage(data) {
  return request({
    url: '/message/message/delete',
    method: 'post',
    data: data
  })
}
